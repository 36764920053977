.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

body {
  background-color: white;
}

input {
  border-radius: 3px;
}

#wordSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.wordChunk {
  width: auto;
  padding: 0px 5px;
}

.singleInput {
  height: 2.5em;
  width: 1.5em;
  text-align: center;
  margin: 1px;
  font-weight: bold;
}

.line1 {
  background-color: rgb(232, 187, 184);
  border-color: transparent;
}

.line2 {
  background-color: rgb(241, 210, 171);
  border-color: transparent;
}

.line3 {
  background-color: rgb(252, 238, 163);
  border-color: transparent;
}

.line4 {
  background-color: rgb(222, 231, 168);
  border-color: transparent;
}

.line5 {
  background-color: rgb(196, 220, 194);
  border-color: transparent;
}

.line6 {
  background-color: rgb(187, 206, 234);
  border-color: transparent;
}

.line7 {
  background-color: rgb(210, 188, 215);
  border-color: transparent;
}

.line8 {
  background-color: rgb(197, 190, 214);
  border-color: transparent;
}

.line9 {
  background-color: rgb(207, 210, 209);
  border-color: transparent;
  width: 3em;
}

.big {
  border: 1px solid #babbbf;
  height: 3em;
  width: 2em;
}

.darkBg {
  background-color: rgb(207, 210, 209);
}

#guessSection {
  margin-top: 10px;
}

.guesses {
  height: 2.5em;
  width: 6em;
  border: 1px solid #dadbdf;
  margin: 1px;
  font-weight: bold;
}

#charList {
  margin: 15px 10px;
  font-size: 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.activeChar {
  color: #282c34;
  font-weight: 900;
  margin: 2px;
}

.inactiveChar {
  color: #babbbf;
  font-weight: 900;
  margin: 2px;
}

footer {
  margin-top: 15px;
  padding: 15px;
  border-top: 1px solid #babbbf;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.blurb {
  margin: 0px 10px;
  color: #777777;
}

button {
  background-color: white;
  color: #777777;
  border: 1px solid #babbbf;
  border-radius: 3px;
  height: 3em;
}
